<template>
  <router-view />
</template>

<script>
  export default {
    // created() {
    //   console.log(this.$isMobile())
    // }

  }
</script>

<style>
  .btn-primary {
    color: #fff;
    background-color: #007ABD;
    border-color: #007ABD;
  }

  .btn-outline-primary {
    color: #007ABD;
    border-color: #007ABD;
  }

  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007ABD;
    border-color: #007ABD;
  }

  .btn-primary:hover {
    color: #fff;
    background-color: #007ABD;
    border-color: #007ABD;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007ABD;
  }
</style>