import { createStore } from 'vuex'

export default createStore({
  state: {
    categories: null
  },
  mutations: {
    SET_CATEGORIES(state, payload) {
			state.categories = payload
		},
  },
  actions: {
  },
  modules: {
  }
})
